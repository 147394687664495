import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <p style={{
        fontSize: `7rem`,
        fontFamily: `FixedSys`,
        lineHeight: 1,
        margin: 0,
        maxWidth: 600,
        textTransform: `lowercase`
      }}>
        {siteTitle}
      </p>
      <p>
        Hi, I'm Mateus. I'm a growth marketer at Wishpond and I've worked in several aspects of marketing to make it grow. <Link>I occasionally write about marketing and web development.</Link>
      </p>
    </div>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
