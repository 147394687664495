import React from "react"
import { Link } from "gatsby"

import { Container, Navbar, Nav, Form, FormControl, Button } from "react-bootstrap"

const CustomNavbar = ({ pageInfo }) => {
  console.log(pageInfo)
  return (
    <>
      <Container>
        <Navbar expand="lg" id="site-navbar">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
              <Nav>
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/blog/">About</Nav.Link>
                <Nav.Link href="/subscribe/">Subscribe</Nav.Link>
                <Nav.Link href="/contact/">Contact</Nav.Link>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
      </Container>
    </>
  )
}

export default CustomNavbar
